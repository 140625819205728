// File: ./front/src/components/sections/footers/footer-page.component.tsx

import React, { useState } from "react";

import FooterSocial from "@/components/sections/footers/FooterSocial";
import FooterCopyright from "@/components/sections/footers/FooterCopyright";
import FooterMenu from "@/components/sections/footers/FooterMenu";

import PinModal from "@/components/modals/pin-modal.component";

interface FooterPageProps {
  onAdminToggle: (isVisible: boolean) => void;
  isTestFooterVisible: boolean; // New prop to track visibility state
}

const FooterPage: React.FC<FooterPageProps> = ({ onAdminToggle, isTestFooterVisible }) => {
  const [isPinModalOpen, setIsPinModalOpen] = useState(false);

  const handleAdminAccess = (granted: boolean) => {
    onAdminToggle(granted); // Update visibility of FooterTest
    setIsPinModalOpen(false); // Close modal
    if (granted) {
      localStorage.setItem("isTestFooterVisible", "true");
    } else {
      localStorage.removeItem("isTestFooterVisible");
    }
  };

  return (
    <div className="footer__page">
      {/* Social Section */}
      <div className="footer__social">
        <FooterSocial />
      </div>

      {/* Menu and Copyright Section */}
      <div className="footer__row">
        <FooterCopyright />
        <FooterMenu />
      </div>

      {/* Admin Button */}
      <button
        className="footer__admin-button"
        onClick={() => {
          if (isTestFooterVisible) {
            handleAdminAccess(false); // Close FooterTest directly
          } else {
            setIsPinModalOpen(true); // Open PIN modal
          }
        }}
        aria-label={isTestFooterVisible ? "Close Test Footer" : "Admin Access"}
      >
        {isTestFooterVisible ? "Close" : "Administrator"}
      </button>

      {/* Pin Modal */}
      {isPinModalOpen && (
        <PinModal
          isOpen={isPinModalOpen}
          onClose={() => setIsPinModalOpen(false)}
          requiredPin="4545"
          onPinSuccess={() => handleAdminAccess(true)}
        />
      )}
    </div>
  );
};

export default FooterPage;
