// File: front/src/constants/pagination.constants.ts
// Last change: Added location search debounce constant

// Frontend pagination (client-side)
export const COUNTRY_PAGE_SIZE = 5;       // How many countries to show initially
export const LOCATION_PAGE_SIZE = 20;     // How many locations to show per page

// UI constants
export const UI_PAGE_SIZE = 10;           // Controls dropdown navigation
export const MAX_COUNTRIES = 84;          // Total number of available countries

// Search timing constants (in milliseconds)
export const DEBOUNCE_LOCATION_SEARCH = 250;   // Location search input debounce