// File: front/src/data/mockData.ts
// Last change: Created mock data for testing client and carrier information

export const mockClientData = [
  {
      distance: "50 km",
      vehicleType: "Truck",
      availabilityTime: "10:00",
      eta: "12:00",
  }
];

export const mockCarrierData = [
  {
      pickup: "Bratislava",
      delivery: "Kosice",
      pallets: 10,
      weight: "3.5t",
  }
];
