// File: src/constants/letterPaths.ts
// Last change: Optimized SVG paths and added validation type

// Type for letter validáciu
type LetterKey = 'S' | 'E' | 'N' | 'D' | 'L' | 'I' | 'V' | 'R';

// Optimalizované SVG cesty pre každé písmeno
const Letters: Record<LetterKey, string> = {
  S: "M20.19 40.87Q20.19 42.65 19.47 44.21Q18.75 45.78 17.54 46.95Q16.33 48.12 14.73 48.79Q13.13 49.46 11.35 49.46L8.84 49.46Q7.06 49.46 5.46 48.79Q3.86 48.12 2.65 46.95Q1.44 45.78 0.72 44.21Q0 42.65 0 40.87L0 39.48L3.15 38.96L3.15 40.87Q3.15 42.04 3.63 43.05Q4.10 44.07 4.91 44.84Q5.71 45.61 6.77 46.04Q7.84 46.48 9.01 46.48L11.21 46.48Q12.38 46.48 13.43 46.04Q14.48 45.61 15.28 44.84Q16.09 44.07 16.56 43.05Q17.04 42.04 17.04 40.87L17.04 39.82Q17.04 38.01 16.33 36.82Q15.63 35.62 14.45 34.78Q13.28 33.94 11.80 33.35Q10.33 32.76 8.76 32.18Q7.20 31.59 5.73 30.88Q4.25 30.18 3.08 29.11Q1.90 28.05 1.20 26.51Q0.49 24.98 0.49 22.73L0.49 22.07Q0.49 20.29 1.16 18.73Q1.83 17.16 3.00 15.99Q4.17 14.82 5.75 14.15Q7.32 13.48 9.11 13.48L11.35 13.48Q13.13 13.48 14.70 14.15Q16.26 14.82 17.43 15.99Q18.60 17.16 19.27 18.73Q19.95 20.29 19.95 22.07L19.95 22.97L16.80 23.49L16.80 22.07Q16.80 20.90 16.36 19.89Q15.92 18.87 15.16 18.12Q14.40 17.36 13.39 16.92Q12.38 16.48 11.21 16.48L9.25 16.48Q8.08 16.48 7.07 16.92Q6.05 17.36 5.29 18.12Q4.52 18.87 4.08 19.89Q3.64 20.90 3.64 22.07L3.64 22.73Q3.64 24.41 4.35 25.56Q5.05 26.71 6.23 27.53Q7.40 28.34 8.87 28.94Q10.35 29.54 11.91 30.16Q13.48 30.79 14.95 31.53Q16.43 32.28 17.60 33.36Q18.77 34.45 19.48 36.01Q20.19 37.57 20.19 39.82L20.19 40.87Z",
  E: "M0 48.97L0 13.96L19.70 13.96L19.70 16.97L3.15 16.97L3.15 29.98L16.55 29.98L16.55 32.98L3.15 32.98L3.15 45.97L19.70 45.97L19.70 48.97L0 48.97Z",
  N: "M18.04 48.97L3.44 23.49L3.15 21.97L3.15 48.97L0 48.97L0 13.96L1.64 13.96L16.24 39.48L16.55 40.97L16.55 13.96L19.70 13.96L19.70 48.97L18.04 48.97Z",
  D: "M19.70 40.38Q19.70 42.16 19.03 43.73Q18.36 45.29 17.19 46.46Q16.02 47.63 14.44 48.30Q12.87 48.97 11.08 48.97L0 48.97L0 13.96L11.08 13.96Q12.87 13.96 14.44 14.64Q16.02 15.31 17.19 16.48Q18.36 17.65 19.03 19.23Q19.70 20.80 19.70 22.58L19.70 40.38L19.70 40.38M16.55 22.58Q16.55 21.41 16.11 20.40Q15.67 19.38 14.90 18.62Q14.14 17.85 13.12 17.41Q12.11 16.97 10.94 16.97L3.15 16.97L3.15 45.97L10.94 45.97Q12.11 45.97 13.12 45.53Q14.14 45.09 14.90 44.34Q15.67 43.58 16.11 42.57Q16.55 41.55 16.55 40.38L16.55 22.58Z",
  L: "M0 48.97L0 13.96L3.15 13.96L3.15 45.97L16.55 45.97L16.55 48.97L0 48.97Z",
  I: "M0 48.97L0 13.96L3.15 13.96L3.15 48.97L0 48.97Z",
  V: "M11.01 41.28L11.11 42.58L11.21 41.28L18.97 13.96L22.22 13.96L11.91 48.97L10.25 48.97L0 13.96L3.27 13.96L11.01 41.28Z",
  R: "M16.97 48.97L7.47 34.03L3.15 34.03L3.15 48.97L0 48.97L0 13.96L11.11 13.96Q12.89 13.96 14.45 14.64Q16.02 15.31 17.19 16.48Q18.36 17.65 19.03 19.23Q19.70 20.80 19.70 22.58L19.70 25.42Q19.70 27.20 19.03 28.77Q18.36 30.35 17.19 31.52Q16.02 32.69 14.45 33.36Q12.89 34.03 11.11 34.03L20.61 48.97L16.97 48.97M16.55 22.58Q16.55 21.41 16.11 20.40Q15.67 19.38 14.92 18.62Q14.16 17.85 13.15 17.41Q12.13 16.97 10.96 16.97L3.15 16.97L3.15 31.03L10.96 31.03Q12.13 31.03 13.15 30.59Q14.16 30.15 14.92 29.38Q15.67 28.61 16.11 27.60Q16.55 26.59 16.55 25.42L16.55 22.58Z"};
  
  export type { LetterKey };
  export default Letters;
  